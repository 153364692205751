import {empty, Observable, of as observableOf, Subject, Subscription, throwError as observableThrowError} from 'rxjs';
import {catchError, delay, map, take} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {FileType, HttpAction} from './enums';
import {AppStateConstants, GlobalConstants, GlobalTableNames} from '../globals';
import {SessionStorageService} from '../localstorage/session-storage.service';
import {ApiQueueModel, PostResponse} from './models';
import {isNullOrUndefined} from 'app/shared/utils/global.utils';
import * as moment from 'moment';
import {AppStateService} from '../app.service';
import {ApiQueueService} from './api-queue.service';
import {LoggerService} from '../services/logger.service';
import {ApiLogModel} from './models/api-log.model';
import {ApiError} from './models/api-error';
import {IndexedDBService} from '../indexedDB/indexedDB.service';

@Injectable()
export class ApiHttpService implements OnDestroy {
    private _monitorActive: boolean;
    private _firstStartup: boolean;
    private _queueSubscription: Subscription;
    private _queueEmptyObserver: Subject<void> = new Subject<void>();

    constructor(private _apiQueue: ApiQueueService,
                private _http: HttpClient,
                private _appState: AppStateService,
                private _dbService: IndexedDBService) {
        this._monitorActive = false;
        this._firstStartup = true;
        this._queueSubscription = this._apiQueue.entryAdded.subscribe(apiCall => {
            if (this._monitorActive && apiCall) {
                this._processApiCall(apiCall);
            }
        },
            error => {
                LoggerService.Error('', error);
                this._monitorActive = false;
            }
        );
    }

    ngOnDestroy(): void {
        if (!isNullOrUndefined(this._queueSubscription)) {
            this._queueSubscription.unsubscribe();
            this._queueSubscription = null;
        }
    }


    startQueueService() {
        if (!this._monitorActive) {
            this._monitorActive = true;
            this._processCurrentQueue();
        }
    }

    stopQueueService() {
        this._monitorActive = false;
    }

    private _processApiCall(apiCall: ApiQueueModel): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if ((!this._appState.get(AppStateConstants.connected)) || (!this._appState.get(AppStateConstants.online))) {
                this.stopQueueService();
                resolve(true);
                return;
            }
            // 2019-01-18: "FailedApiCalls" Disabled because it's causing lots of TrackJS errors.
            const dumpThisCall = (apiCall.path.indexOf('api/FailedApiCalls') > -1);
            const apiObservable = dumpThisCall ? observableOf(true) : this.callApi(apiCall);
            if (!isNullOrUndefined(apiObservable)) {
                // Got a valid call from the queue
                apiObservable.toPromise().then(() => {
                    const postPromises = [];
                    // Save to the file store if so required
                    if (!dumpThisCall && apiCall.storeAfterProcessing) {
                        postPromises.push(this.storeContent(apiCall, true));
                    }
                    // Delete this API call from the queue since we don't need to keep the response.
                    postPromises.push(
                        this._apiQueue.deleteRow(apiCall.queueId).then(() => {
                            LoggerService.Log('deleted entry just processed');
                        }).catch((error) => {
                            LoggerService.Error('', error);
                        }
                        )
                    );
                    Promise.all(postPromises).then(() => {
                        resolve(true);
                    }).catch(() => {
                        // the API fall passed, so resolve true.
                        resolve(true);
                    });
                }).catch(error => {
                    if (error.toString().indexOf('400') !== -1) {
                        // Got to API, but error => don't re-process this request.
                        this._removeFromQueue(apiCall, error).then(() => {
                            resolve(false);
                        });
                    } else {
                        this._checkPing().then(online => {
                            if (online) {
                                // Got to API, but error => don't re-process this request.
                                this._removeFromQueue(apiCall, error).then(() => {
                                    resolve(false);
                                });
                            } else {
                                // Did not get to API => re-process this error.
                                LoggerService.Error('API Service Stopped because of error: \n', error);
                                this.stopQueueService();
                                resolve(false);
                            }
                        });
                    }
                });

            } else {
                // nothing to do - so return true.
                resolve(true);
            }
        });
    }

    private _checkPing(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let resolved = false;
            const apiURL = this._appState.get(AppStateConstants.lightSailServerURL) + 'api/Ping/Unauthenticated';
            this._http.get(apiURL).subscribe(() => {
                if (!resolved) {
                    resolved = true;
                    resolve(true);
                }
            }, () => {
                if (!resolved) {
                    resolved = true;
                    resolve(false);
                }
            }, () => {
                if (!resolved) {
                    resolved = true;
                    resolve(false);
                }
            });
        });
    }

    private _removeFromQueue(apiCall: ApiQueueModel, error): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            apiCall.processInd = 1;
            this._apiQueue.update(apiCall).then(() => {
                if (apiCall.storeAfterProcessing) {
                    this.storeContent(apiCall, false, error).then(() => {
                        resolve(true);
                    }).catch(() => {
                        // resolve true because item was removed from queue.
                        resolve(true);
                    });
                } else {
                    resolve(true);
                }
            }).catch(() => {
                resolve(false);
            });
        });
    }

    private _processCurrentQueue() {
        // Start off the "Queue Monitor" Web-Worker
        if (this._monitorActive) {
            const backlogQueueObservable = this._apiQueue.getBacklogApiQueue(this._firstStartup)
                .pipe(delay(new Date(Date.now() + 100)))
                .subscribe(
                    apiCall => {
                        this._firstStartup = false;
                        if (!this._monitorActive && backlogQueueObservable) {
                            backlogQueueObservable.unsubscribe();
                        } else if (apiCall) {
                            this._processApiCall(apiCall);
                        } else {
                            // Trigger queueEmpty event
                            this._queueEmptyObserver.next();
                        }
                    },
                    error => {
                        LoggerService.Error('', error);
                        this._monitorActive = false;
                    },
                    () => {
                        if (backlogQueueObservable) {
                            backlogQueueObservable.unsubscribe();
                        }
                    }
                );
        }
    }

    private storeContent(apiCall: ApiQueueModel, success: boolean, failureReason: string = null): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const now = moment.utc().toDate();
            const storeData = new ApiLogModel(apiCall);
            storeData.processInd = success ? 1 : 0;
            storeData.sentDate = now;
            storeData.successInd = success;
            storeData.failureReason = failureReason;
            this._apiQueue.writeToLog(storeData).then(() => {
                resolve(true);
            }).catch(() => {
                resolve(false);
            });
        });
    }

    public callApi<T>(apiCall: ApiQueueModel): Observable<T | any> {
        let response: Observable<any> = null;
        let headers: HttpHeaders;
        const apiURL: string = apiCall.isEventHub ?
            apiCall.path : // event hub calls have an absolute path
            apiCall.serverUrl + apiCall.path; // regular API calls have a path relative to the server
        const body = apiCall.isFormData ? apiCall.content : JSON.stringify(apiCall.content);

        LoggerService.Log('Calling ' + ((apiCall.queueId) ? '(apiCall=' + apiCall.queueId + ')' : 'apiURL') + ':' + apiURL);
        switch (apiCall.action) {
            case HttpAction.Get.toString():
                headers = ApiHttpService.getRequestHeaders(false, null,
                    apiCall.schoolIdHeader, apiCall.userGroupOrganizationIdHeader, apiCall.userIdHeader,
                  null, null, apiCall.authKey, apiCall.shardKey);

                if (apiCall.ngHttpCachingHeaders) {
                  headers = Object.keys(apiCall.ngHttpCachingHeaders)
                    .reduce((acc, header) => acc.append(header, apiCall.ngHttpCachingHeaders[header]), headers)
                }

                response = this._http.get(apiURL,
                    {
                        headers: headers,
                        observe: 'response',
                        context: apiCall.httpContext
                    })
                    .pipe(
                        map(ApiHttpService.extractData),
                        catchError(error => this.handleError(error, apiCall))
                    );
                break;
            case HttpAction.Post.toString():
                // EventHub calls are always POSTs, so no need for these additional parameters in other cases
                headers = ApiHttpService.getRequestHeaders(apiCall.isEventHub, apiCall.messageType,
                    apiCall.schoolIdHeader, apiCall.userGroupOrganizationIdHeader, apiCall.userIdHeader, null, apiCall.isFormData,
                  apiCall.authKey, apiCall.shardKey);

                if (apiCall.ngHttpCachingHeaders) {
                  headers = Object.keys(apiCall.ngHttpCachingHeaders)
                    .reduce((acc, header) => acc.append(header, apiCall.ngHttpCachingHeaders[header]), headers)
                }

                response = this._http.post(apiURL, body,
                    {
                        headers: headers,
                        observe: 'response',
                        context: apiCall.httpContext
                    })
                    .pipe(
                        map(ApiHttpService.extractStatus),
                        catchError(error => this.handleError(error, apiCall))
                    );
                break;
            case HttpAction.PostWithBodyResponse.toString():
                // EventHub calls are always POSTs, so no need for these additional parameters in other cases
                headers = ApiHttpService.getRequestHeaders(apiCall.isEventHub, apiCall.messageType,
                    apiCall.schoolIdHeader, apiCall.userGroupOrganizationIdHeader, apiCall.userIdHeader, null, apiCall.isFormData,
                  apiCall.authKey, apiCall.shardKey);

                if (apiCall.ngHttpCachingHeaders) {
                  headers = Object.keys(apiCall.ngHttpCachingHeaders)
                    .reduce((acc, header) => acc.append(header, apiCall.ngHttpCachingHeaders[header]), headers)
                }

                response = this._http.post(apiURL, body,
                    {
                        headers: headers,
                        observe: 'response',
                        context: apiCall.httpContext
                    })
                    .pipe(
                        map(ApiHttpService.extractDataOrStatus),
                        catchError(error => this.handleError(error, apiCall))
                    );
                break;
            case HttpAction.PostWithProgress.toString():
                // EventHub calls are always POSTs, so no need for these additional parameters in other cases
                headers = ApiHttpService.getRequestHeaders(apiCall.isEventHub, apiCall.messageType,
                    apiCall.schoolIdHeader, apiCall.userGroupOrganizationIdHeader, apiCall.userIdHeader, null, apiCall.isFormData,
                  apiCall.authKey, apiCall.shardKey);

                if (apiCall.ngHttpCachingHeaders) {
                  headers = Object.keys(apiCall.ngHttpCachingHeaders)
                    .reduce((acc, header) => acc.append(header, apiCall.ngHttpCachingHeaders[header]), headers)
                }

                response = this._http.post(apiURL, body,
                    {
                        headers: headers,
                        reportProgress: true,
                        observe: 'events',
                        context: apiCall.httpContext
                    })
                    .pipe(
                        catchError(error => this.handleError(error, apiCall))
                    );
                break;
            case HttpAction.Put.toString():
                headers = ApiHttpService.getRequestHeaders(false, null,
                    apiCall.schoolIdHeader, apiCall.userGroupOrganizationIdHeader, apiCall.userIdHeader, null, apiCall.isFormData,
                  apiCall.authKey, apiCall.shardKey);

                if (apiCall.ngHttpCachingHeaders) {
                  headers = Object.keys(apiCall.ngHttpCachingHeaders)
                    .reduce((acc, header) => acc.append(header, apiCall.ngHttpCachingHeaders[header]), headers)
                }

                response = this._http.put(apiURL, body,
                    {
                        headers: headers,
                        observe: 'response',
                        context: apiCall.httpContext
                    })
                    .pipe(
                        map(ApiHttpService.extractDataOrStatus),
                        catchError(error => this.handleError(error, apiCall))
                    );
                break;
            case HttpAction.Patch.toString():
                headers = ApiHttpService.getRequestHeaders(false, null,
                    apiCall.schoolIdHeader, apiCall.userGroupOrganizationIdHeader, apiCall.userIdHeader,
                  null, null, apiCall.authKey, apiCall.shardKey);

                if (apiCall.ngHttpCachingHeaders) {
                  headers = Object.keys(apiCall.ngHttpCachingHeaders)
                    .reduce((acc, header) => acc.append(header, apiCall.ngHttpCachingHeaders[header]), headers)
                }

                response = this._http.patch(apiURL, body,
                    {
                        headers: headers,
                        observe: 'response',
                        context: apiCall.httpContext
                    })
                    .pipe(
                        map(ApiHttpService.extractDataOrStatus),
                        catchError(error => this.handleError(error, apiCall))
                    );
                break;
            case HttpAction.Delete.toString():
                headers = ApiHttpService.getRequestHeaders(false, null,
                    apiCall.schoolIdHeader, apiCall.userGroupOrganizationIdHeader, apiCall.userIdHeader, null, apiCall.isFormData,
                  apiCall.authKey, apiCall.shardKey);
                response = this._http.delete(apiURL,
                    {
                        headers: headers,
                        observe: 'response'
                    }).pipe(
                        map(ApiHttpService.extractDataOrStatus),
                        catchError(error => this.handleError(error, apiCall))
                    );
                break;
            case HttpAction.DeleteWithBody.toString():
                /* this method is for handling delete requests with body, as w3 standards cannot handle a body delete method
                * to prevent possible fallout from other api frameworks.
                * */
                headers = ApiHttpService.getRequestHeaders(false, null,
                    apiCall.schoolIdHeader, apiCall.userGroupOrganizationIdHeader, apiCall.userIdHeader, null,
                  null, apiCall.authKey, apiCall.shardKey);

                response = this._http.request('DELETE', apiURL,
                    {
                        headers: headers,
                        observe: 'response',
                        body: apiCall.content
                    }).pipe(
                        map(ApiHttpService.extractDataOrStatus),
                        catchError(error => this.handleError(error, apiCall))
                    );
                break;
            case HttpAction.Download.toString():
                headers = new HttpHeaders();
                switch (apiCall.downloadFileType) {
                    case FileType.Image:
                        const authKey: string = apiCall.authKey || SessionStorageService.Get(GlobalConstants.AuthKey);
                        const shardKey: string = apiCall.shardKey || SessionStorageService.Get(GlobalConstants.ShardKey);
                        headers = headers.append('Authorization', 'bearer ' + authKey);
                        headers = headers.append('Content-Type', 'image/jpeg');
                        if (shardKey) {
                            headers = headers.append('ShardKey', shardKey);
                        }
                        break;
                    case FileType.Text:
                        headers = headers.append('Content-Type', 'text/plain');
                        break;
                }

                const startTime = new Date();
                response = this._http.get(apiURL, {
                    headers,
                    observe: 'response',
                    responseType: 'blob'
                }).pipe(
                    map(res => this.extractBlob(res, startTime)),
                    catchError(error => this.handleError(error, apiCall)));
                break;
        }

        return this.preventAuthorizedRequestWithoutAuthorizationHeader(response, headers, apiCall.skipAuthCheck);
    }

    static extractDataOrStatus(res: HttpResponse<any>) {
        //If data is returned fromAPI we will pass that to caller.
        //If body is empty we utilize the return status.
        const body = (res.body == null || (res.body as string).length === 0 && !Array.isArray(res.body)) ? res.status : res.body;
        return body || {};
    }

    static extractStatus(res: HttpResponse<any>): PostResponse {
        const location = res.headers.get('Location');
        const locParts = location != null ? location.split('/') : null;
        const objectId = location != null ? locParts[locParts.length - 1] : '';
        return {
            status: res.status,
            location: location,
            objectId: objectId,
            serverDate: res.headers.get('Date'),
            url: res.url != null ? res.url : ''
        };
    }

    static extractData(res: HttpResponse<any>) {
        const body = res.body || '';
        return (!isNullOrUndefined(body) && body !== '') ? body : {};
    }

    private extractBlob(res: HttpResponse<Object>, startTime: any) {
        const endTime = new Date();
        const totalTime = endTime.getTime() - startTime.getTime();
        const contentBlob = new Blob([res.body as any], {type: 'application/octet-binary'});
        return {
            status: res.status,
            downloadTime: totalTime,
            data: contentBlob
        };
    }

    private handleError(error: HttpErrorResponse | any, apiCall: ApiQueueModel) {
        // In a real world app, we might use a remote logging infrastructure
        if (apiCall.path.indexOf('api/FailedApiCalls') === -1) {
            const request = {
                userId: SessionStorageService.Get(GlobalConstants.UserId),
                eventTime: moment.utc().toDate(),
                httpVerb: HttpAction[apiCall.action],
                headers: JSON.stringify(error.headers),
                body: JSON.stringify(apiCall.content),
                endpointUrl: apiCall.serverUrl + apiCall.path,
                error: error.status + ': ' + error.message
            };
        }

        let errMsg: string;
        let apiErrorBody: any;
        if (error instanceof HttpErrorResponse) {
            const errBody = (error && error.message) ? JSON.parse(JSON.stringify(error || null)) : '';
            const err = !isNullOrUndefined(errBody.error) ? JSON.stringify(errBody.error) : JSON.stringify(errBody);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
            apiErrorBody = (error && error.error && error.error instanceof ProgressEvent) ? null : errBody.error;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        return observableThrowError(new ApiError(errMsg, apiErrorBody));
    }

    static getRequestHeaders(isEventHub: boolean = false, messageType: string = null, schoolIdHeader: string = null,
        userGroupOrganizationIdHeader: string = null, userIdHeader: string = null,
        acceptLanguageHeader: string = null, isFormData = null, auth: string = null, shard: string = null): HttpHeaders {
        let authorization: string;
        if (isEventHub) {
            authorization = SessionStorageService.Get(GlobalConstants.EventHubAuthToken);
        } else {
            const authKey = auth || SessionStorageService.Get(GlobalConstants.AuthKey);
            authorization = authKey ? `bearer ${authKey}` : '';
        }
        let result = new HttpHeaders();

        if (!isFormData) {
            result = result.append('Content-Type', 'application/json');
        }

        result = result.append('Authorization', authorization);
        const shardKey = shard || SessionStorageService.Get(GlobalConstants.ShardKey);
        if (shardKey) {
            result = result.append('ShardKey', shardKey);
        }
        if (messageType) {
            result = result.append('MessageType', messageType);
        }
        if (!isNullOrUndefined(schoolIdHeader)) {
            result = result.append('SchoolId', schoolIdHeader);
        }
        if (!isNullOrUndefined(userGroupOrganizationIdHeader)) {
            result = result.append('UserGroupOrganizationId', userGroupOrganizationIdHeader);
        }
        if (!isNullOrUndefined(userIdHeader)) {
            result = result.append('UserId', userIdHeader);
        }
        if (!isNullOrUndefined(acceptLanguageHeader)) {
            result = result.append('Accept-Language', acceptLanguageHeader);
        }
        return result;
    }

    public preventAuthorizedRequestWithoutAuthorizationHeader(requestObservable: Observable<any>,
        headers: HttpHeaders, skipAuthCheck = false): Observable<any> {
      if (skipAuthCheck) {
        return requestObservable;
      }

        if (headers.has('Authorization') && !headers.get('Authorization')) {
            LoggerService.Error('Authenticated API method can\'t be called because authorization token is missed');
            return empty();
        } else {
            return requestObservable;
        }
    }

    public async queueEmpty(): Promise<void> {
        // Set up the promise first in case we need it later, to avoid a race condition
        const queueEmptyPromise = new Promise<void>((resolve, reject) => {
            const subscription = this._queueEmptyObserver.pipe(take(1)).subscribe(() => { resolve(); subscription.unsubscribe(); }, reject);
        });
        // See if it's already empty
        const {length} = (await this._dbService.getAll(GlobalTableNames.ApiQueue)).filter(item => !item.processInd);
        return length === 0 ? null : queueEmptyPromise;
    }
}
